export const content = [
  {
    id: 'kompleksowe-kasetony',
    article: `<p class="thin">W dzisiejszych czasach marketing jest podstawą działania każdej firmy. Niezależnie od branży, dbałość o odpowiednie reklamy jest niezbędna. W naszym przedsiębiorstwie znajdziesz przydatne narzędzia i akcesoria świetlne, które w łatwy
      sposób mogą wspierać marketing Twojej firmy. Posiadamy między innymi kaseton podświetlany, który jest doskonale widoczny o każdej porze dnia i nocy. Wysoka efektywność tego rozwiązania zawdzięczana jest przede wszystkim możliwościom uniwersalnego stosowania. Reklama świetlna może być z powodzeniem wykorzystywana w każdym
      okresie, co gwarantuje wysoką skuteczność.</p>
      \n ## Kompleksowe <span class="orange">kasetony</span>
      \n Jedną z wielu specjalności naszego przedsiębiorstwa jest kompleksowy kaseton reklamowy, który jest doskonale widoczny z daleka zarówno w dzień, jak i w nocy. Podświetlane rozwiązania są w naszym przedsiębiorstwie podzielona na warianty:
      \n * dibond,\n * nieregularne,\n * plexi,\n * vinyl.
      \n Każde z dostępnych rozwiązań może różnić się specyfikacją czy wymiarami. Wszystkie z nich są jednak wyjątkowo skuteczne i powszechnie stosowane. Warianty świetlne pomagają wyróżnić się na tle otoczenia. Kolorowe lampy wykorzystywane w odpowiednim miejscu przykuwają wzrok, dzięki czemu pozytywnie wpływają na odbiór przez potencjalnych konsumentów.
      \n Dzięki możliwości wyboru nieregularnych kształtów, podświetlane kasetony reklamowe mogą zostać umieszczane w każdym miejscu. Tego typu warianty doskonale sprawdzają się przede wszystkim w lokacjach, w których wolne miejsce jest znacznie ograniczone. Wybierając podświetlany kaseton nieregularny możesz w prosty sposób wybrać jego rozmiar, a także kształt. Najpopularniejsze z nich to koła bądź warianty z zaokrąglonymi rogami.  
    `,
  },
  {
    id: 'reklama-szyta-na-miarę',
    article: `## Reklama szyta na <span class="orange">miarę</span>
    \n Nasze kasetony świetlne są jedyne w swoim rodzaju. Dzięki szerokim możliwościom dopasujemy kształt oraz wielkość do indywidualnych preferencji każdego klienta. Jeżeli nadal nie wiesz, na który z dostępnych wariantów powinieneś się zdecydować, chętnie Ci pomożemy. Nasi eksperci wyjaśnią w sposób merytoryczny i przejrzysty, dlaczego dane
    rozwiązanie może okazać się w Twojej lokalizacji najbardziej skuteczne i efektywne. Gwarantujemy, że wykorzystując nasze reklamy podświetlane zyskasz nowych klientów na długie lata.
    `,
  },
];
